/**
 * List Schema of DappSocial
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {DappSocial} from '@/model/resource/DappSocial'

/* TODO: review generated schema */
export class ListDappSocialSchema extends DefaultSchema {
  readonly name = 'ListDappSocial'

  readonly fieldSet: FieldSet<DappSocial> = {
    dapp: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.dapp?.$tag,
      },
    }),
    socialType: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.socialType?.$tag,
      },
    }),
    url: (schema): FieldComponent => ({
      is: Component.RenderAnchor,
      bind: {
        href: schema.model.url,
        label: schema.model.url,
        target: '_blank',
      },
    }),
  }
}
