





























































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterDappSocial from '@/components/filters/FilterDappSocial.vue'
import {DappSocial} from '@/model/resource/DappSocial'
import {DappSocialCollection} from '@/model/collection/DappSocialCollection'
import {ListDappSocialSchema} from '@/schema/resource/DappSocial/ListDappSocialSchema'
import {ExportDappSocialSchema} from '@/schema/resource/DappSocial/ExportDappSocialSchema'
import {Dapp} from '@/model/resource/Dapp'
import PersistDappSocialView from '@/views/persist/PersistDappSocialView.vue'

@Component({
  components: {PersistDappSocialView, FilterToggle, FilterDappSocial},
})
export default class ListDappSocialView extends Mixins(MixinRouteMatch) {
  @Prop() dapp!: Dapp
  schema = new ListDappSocialSchema()
  collection = new DappSocialCollection()
  isAdding = false

  get getItems() {
    return [...this.collection.items, ...this.collection.editionList]
  }

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    this.collection.dapp = [this.dapp]
    await this.$await.run('hardQuery', () => this.collection.listDappSocial())
    this.$root.$on('dappSaved', this.persistPendindItem)
  }

  beforeDestroy() {
    this.$root.$off('dappSaved', this.persistPendindItem)
  }

  persistPendindItem(id: number) {
    this.collection.editionList.forEach(contract => {
      contract.idDappFk = id
      contract.persistDappSocial()
    })
    this.collection.editionList = []
  }

  goToPersistView(item: DappSocial) {
    this.$nav.pushByName('editDappSocial', item.idDappFk, item.idSocialTypeFk)
  }

  async removeItem(item: DappSocial) {
    await this.$dialog.remove()
    const hasItem = this.collection.items.some(
      social =>
        social.url === item.url &&
        social.socialType?.title == item.socialType?.title
    )
    if (hasItem) {
      await item.removeDappSocial(item.idDappFk, item.idSocialTypeFk)
      await this.collection.queryAsPage()
    } else {
      this.collection.editionList = this.collection.editionList.filter(
        ({url, socialType}) =>
          !(item.url === url && item.socialType?.title === socialType?.title)
      )
    }
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new DappSocialCollection().clearFilters().addFilter(params)

    await coll.listExportDappSocial()
    this.$xlsx.downloadFromSchema(coll.items, new ExportDappSocialSchema())
  }

  addNew() {
    this.isAdding = true
  }

  goToList(dappSocial: DappSocial) {
    this.collection.editionList.push(dappSocial)
    this.isAdding = false
  }
}
